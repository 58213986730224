<template>
	<div>
		<v-navigation-drawer v-model="drawer" fixed width="400" right temporary stateless>
			<div class="drawer-wrapper">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
					<div class="font-level-3-bold">Contact Update</div>
					<v-spacer></v-spacer>
					<v-btn depressed tile class="mr-2" v-on:click="closeDrawer()">Cancel</v-btn>
					<v-btn
						tile
						depressed
						color="white--text"
						class="blue darken-4"
						:loading="pageLoading"
						:disabled="pageLoading || !formValid"
						v-on:click="updateContact()"
					>
						Update
					</v-btn>
				</v-flex>

				<v-form
					ref="form"
					id="leadForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="updateContact"
				>
					<div class="drawer-content pt-5 px-5">
						<div class="mb-3">
							<div class="d-flex pt-1 mb-2">
								<label class="fw-500">Contact Name</label>
							</div>
							<v-text-field
								type="text"
								placeholder="Contact Name"
								:loading="pageLoading"
								v-model="contact.contact_name"
								outlined
								hide-details
								color
							>
							</v-text-field>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1 mb-2">
								<label class="fw-500">Email Address</label>
							</div>
							<v-text-field
								type="email"
								placeholder="Email Address"
								v-model="contact.email_address"
								:loading="pageLoading"
								:rules="[vrules.validEmail(contact.email_address, 'Email Address')]"
								outlined
								hide-details
								color
							>
							</v-text-field>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1 mb-4">
								<label class="fw-500">Phone No/Mobile No</label>
							</div>
							<PhoneTemplate
								style="margin-top: -12px"
								hide-details
								id="phone_number"
								v-model="contact.phone_number"
								:loading="pageLoading"
								@loading="($event) => (pageLoading = $event)"
								@validity="phoneValid($event, 'phone_number')"
							></PhoneTemplate>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1 mb-4">
								<label class="fw-500">DID</label>
							</div>
							<PhoneTemplate
								style="margin-top: -12px"
								hide-details
								placeholder="DID"
								id="did"
								v-model="contact.did_number"
								:loading="pageLoading"
								@loading="($event) => (pageLoading = $event)"
								@validity="phoneValid($event, 'did')"
							></PhoneTemplate>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1 mb-4">
								<label class="fw-500">Landline</label>
							</div>
							<PhoneTemplate
								style="margin-top: -12px"
								hide-details
								placeholder="Landline"
								id="land_line_number"
								v-model="contact.land_line_number"
								:loading="pageLoading"
								@loading="($event) => (pageLoading = $event)"
								@validity="phoneValid($event, 'land_line_number')"
							></PhoneTemplate>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1 mb-2">
								<label class="fw-500">Designation</label>
							</div>
							<v-autocomplete
								:items="designationList"
								item-text="text"
								item-value="value"
								:loading="pageLoading"
								hide-details
								outlined
								placeholder="Designation"
								v-model="contact.designation"
							>
							</v-autocomplete>
						</div>
					</div>

					<!-- <v-row class="pl-4 py-6 pr-10 position-relative">
						<v-col cols="12" lg="12">
							<label class="fw-500">Contact Name</label>
						</v-col>
						<v-col cols="12" lg="12">
							<v-text-field
								type="text"
								placeholder="Contact Name"
								v-model="contact.contact_name"
								outlined
								hide-details
								color
							>
							</v-text-field>
						</v-col>
						<v-col cols="12" lg="12">
							<v-text-field
								type="email"
								placeholder="Email Address"
								v-model="contact.email_address"
								:rules="[vrules.validEmail(contact.email_address, 'Email Address')]"
								outlined
								hide-details
								color
							>
							</v-text-field>
						</v-col>
						<v-col cols="12" lg="12" class="d-flex">
							<PhoneTemplate
								style="margin-top: -12px"
								hide-details
								id="phone_number"
								v-model="contact.phone_number"
							></PhoneTemplate>
						</v-col>
						<v-col cols="12" lg="12">
							<v-autocomplete
								:items="designationList"
								item-text="text"
								item-value="value"
								hide-details
								outlined
								placeholder="Designation"
								v-model="contact.designation"
							>
							</v-autocomplete>
						</v-col>
					</v-row> -->
				</v-form>
			</div>
		</v-navigation-drawer>
	</div>
</template>

<script>
import { GET, PUT } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import PhoneTemplate from "@/view/components/Phone";

export default {
	name: "ContactUpdate",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		contactData: {
			type: Object,
		},
		type: {
			type: String,
			default: null,
		},
		updateUrl: {
			type: String,
			default: null,
		},
		relatedId: {
			type: Number,
			default: 0,
		},
	},
	watch: {
		contactData: {
			handler(param) {
				this.contact.contact_name = param.contact_name;
				this.contact.email_address = param.email_address;
				this.contact.phone_number = param.phone_number;
				this.contact.did_number = param.did_number;
				this.contact.designation = param.designation;
				this.contact.country_code = param.country_code;
				this.contact.land_line_number = param.land_line_number;
			},
		},
	},
	data() {
		return {
			phoneCodeList: [],
			designationList: [],
			contact_id: null,
			formValid: true,
			pageLoading: false,
			contact: {
				contact_name: null,
				email_address: null,
				phone_number: null,
				did_number: null,
				designation: null,
				country_code: null,
				land_line_number: null,
			},
			phoneValidity: new Object(),
		};
	},
	components: {
		PhoneTemplate,
	},
	methods: {
		phoneValid(isValid, type) {
			this.phoneValidity[type] = isValid;
		},
		closeDrawer() {
			this.$emit("close", true);
		},
		getSettings() {
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: "customer-setting",
				})
				.then((data) => {
					_this.phoneCodeList = data.code;
					_this.designationList = data.designation;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		updateContact() {
			this.contact_id = this.contactData.id;

			if (Object.keys(this.phoneValidity).length) {
				if (
					this.phoneValidity.phone_number == false ||
					this.phoneValidity.did == false ||
					this.phoneValidity.land_line_number == false
				) {
					this.formValid = false;
				}
			}

			if (!this.$refs["form"].validate() || !this.formValid) {
				return false;
			}

			this.pageLoading = true;
			this.$store
				.dispatch(PUT, {
					url: `${this.updateUrl}/${this.relatedId}/${this.contact_id}`,
					data: this.contact,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Contact updated successfully.",
						},
					]);
					this.pageLoading = false;
					this.phoneValidity = new Object();
					this.$emit("update_contact", true);
				})
				.catch((error) => {
					this.pageLoading = false;
					console.log({
						error,
					});
				});
		},
	},
	mounted() {
		this.getSettings();
	},
};
</script>

<style scoped></style>
